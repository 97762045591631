<template>
<div id="app">
  <router-view />
</div>
</template>

<script>

export default {

}
</script>

<style lang="scss">
body {
    margin: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.dv-scroll-ranking-board {
    .rank {
        color: #5cb3ff!important;
    }
    .inside-column {
        background: rgba(#1370fb,.8)!important;
    }
}
</style>
