import axios from 'axios'
import localStorage from './localStorage'
let request = {
  urlEncode(url = '', data = {}) { // 对象转url参数
    if (JSON.stringify(data) === '{}' || !data) return url
    url += (url.indexOf('?') != -1) ? '' : '?'
    for (let k in data) {
      url += ((url.indexOf('=') != -1) ? '&' : '') + k + '=' + encodeURI(data[k])
    }
    return url
  },
  isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
  },
  Axios(url, params = {
    data: {}
  }) { // Axios发送数据  url 地址    parems数据  config配置
    let _config = {
      type: 'POST', // 请求方式
      headUrl: false, // 从头部url传递参数
      data: {}, // 数据
    }

    let newConfig = Object.assign(_config, params)
    let requestParams = { ...newConfig.data
    }
    let timeout = 60000;
    newConfig.data._t = Date.now();
    let instance = axios.create({
      timeout, // 请求超时时间
      headers: {
        token: localStorage.getStorageSync('token'),
        'Content-Type': 'application/json;charset=utf-8',
      }
    })
    return new Promise((resolve, reject) => {
      instance({
        url: newConfig.headUrl ? request.urlEncode(url, params.data) : url,
        params: newConfig.type === 'GET' ? params.data : '',
        data: params.data,
        method: newConfig.type
        // withCredentials: true
      }).then(res => {
        let httpCode = res.data.code
        let resultData = res.data.data !== undefined ? res.data.data : res.data.resultData
        let msg = res.data.msg

        if (res.status === 200) {
          if (httpCode !== 200) {
            return reject(res.data)
          }
        } else {
          return reject(res.data)
        }
        return resolve(resultData)
      }).catch(err => {
        reject(err)
      }).then(() => {})
    })
  }
}
export default {
  ...Object.freeze(request)
}
