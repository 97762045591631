let localStorage = {
  isNull(Str) {
    var isN = false
    if (Str === null || Str === undefined || Str === 'undefined' || Str === '' || Str === 'null') {
      isN = true
    } else {
      isN = false
    }
    return isN
  },
  setStorageSync(key, value) {
    window.localStorage[key] = JSON.stringify(value);
  },
  getStorageSync(key) {
    if (window.localStorage[key]) {
      try {
        return JSON.parse(window.localStorage[key]);
      } catch (e) {
        return window.localStorage[key];
      }
    }

  },
  removeStorageSync(key) {
    if (!key) return
    window.localStorage.removeItem(key);
    window.sessionStorage.removeItem(key);

  },
  setSessionSync(key, value) {
    window.sessionStorage[key] = JSON.stringify(value);
  },
  getSessionSync(key) {
    if (!this.isNull(window.sessionStorage[key])) {
      try {
        return JSON.parse(window.sessionStorage[key]);
      } catch (e) {
        return window.sessionStorage[key]
      }
    } else {
      return undefined;
    }
  },
  out() {
  }
}
module.exports = Object.freeze(localStorage);
