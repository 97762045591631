const apiUrl = "https://api.cjxy.com.cn/"

//强行覆盖(当分测试库和正式库的时候 再用webpack 配置做 ENV来控制)
// proxyTable = "http://203.195.167.103:30010/"



//console.log("proxyTable="+proxyTable)

//图片上传


let currencyApi = {
  queryData: "community/dataView/queryData", //查询数据大屏的数据
  userList: "community/user/list", //用户数据
}
let api = {
  ...currencyApi,
};
for (let key in api) {
  if (api[key].indexOf("http://") <= -1 && api[key].indexOf("https://") <= -1) {
    api[key] = apiUrl + api[key];
  }
}
export default api
