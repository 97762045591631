import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import request from '@/assets/js/request'
import api from '@/assets/js/url'
import localStorage from '@/assets/js/localStorage'

Vue.use(dataV);

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

import vueSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.use(vueSwiper);
// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';

// 引入全局css
import './assets/scss/style.scss';


//引入echart
import * as echarts from 'echarts';
import 'echarts-wordcloud';



Vue.prototype.$echarts = echarts
Vue.prototype.Axios = request.Axios
Vue.prototype.api = api;


Vue.config.productionTip = false;

// localStorage.setStorageSync("token", 'eyJhbGciOiJSUzI1NiJ9.eyJ1c2VySWQiOiIzMiIsInVzZXJOYW1lIjoi5pyxIiwidXNlclBob25lIjoiMTM3MDcwNzQ1MjAiLCJyb2xlRW51bSI6ImFkbWluIiwiZXhwIjoxNjQyODE5NzU2fQ.ZNmzh5nL6tD02iJGWsAWZkKiNdkL3zxadRtlxrCUWzvs1Cse4jMpVp0mhrAsmDRSVwbCADdhUfQmgrF919BxaNV3veuOlEXCv02kanC355UZqeltACu4pIKp3SVj_l6ss2ax9N2wEbIjbMVrR3IPsojpEXFqOtYwTqylHZ2YPMI')

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
